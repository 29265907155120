import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import {ErrorInfo, InputField, UserDetail} from '../types/';
import * as API from '../utils/api'
import {getFieldByPropName} from "../utils/utils";
import Box from "@mui/material/Box";
import {InputCard} from "../molecules/InputCard";
import {ErrorFeedBack} from "../molecules/ErrorFeedback";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PasswordUpdateCard from "./PasswordUpdateCard";
import TextField from "@mui/material/TextField";
import {parseBulkInvitedUsers} from "../utils/UserUtils";

interface UserDetailCardProps {
}

type UserName = {
    lastname: string
    firstname: string
}

type Invitation = {
    email1: string
    lastname1: string
    firstname1: string
    institution?: string
    email2?: string
    lastname2?: string
    firstname2?: string
    email3?: string
    lastname3?: string
    firstname3?: string

}
const UserDetailCard: React.FC<UserDetailCardProps> = () => {

    const [userDetail, setUserDetail] = useState<UserDetail>()
    const [error, setError] = useState<ErrorInfo>()
    const [openPasswordChange, setOpenPasswordChange] = React.useState(false);
    const [invitedUser, setInvitedUser] = React.useState("")
    const [bulkInvitationData, setBulkInvitationData] = React.useState<{ [key: string]: string }>({})


    const getInputField = (userDetail?: UserDetail): InputField<string>[] => ([
        {type: 'text', propName: 'lastname', label: '姓', value: userDetail?.user.lastname || '', required: true},
        {type: 'text', propName: 'firstname', label: '名', value: userDetail?.user.firstname || '', required: true},
    ])

    const setInputField = (username: UserName, fields: InputField<string>[]) => {
        username.lastname = getFieldByPropName<string>(fields, 'lastname')?.value || '';
        username.firstname = getFieldByPropName<string>(fields, 'firstname')?.value || '';
    }

    const getInvitationInputField = (isAdmin: boolean, data?: { [key: string]: string }): InputField<string>[] => {
        if (isAdmin) {
            const USER_NO=3
            const result: InputField<string>[] = []
            for (let i=1; i<=USER_NO; i++) {
                const required = i === 1
                result.push({
                    type: 'text',
                    propName: `email${i}`,
                    label: `メールアドレス${i}`,
                    value: data?.[`email${i}`] ?? '',
                    required
                })
                    ,
                    result.push({
                        type: 'text',
                        propName: `lastname${i}`,
                        label: '姓',
                        value: data?.[`lastname${i}`] ?? '',
                        required
                    })
                result.push({
                    type: 'text',
                    propName: `firstname${i}`,
                    label: '名',
                    value: data?.[`firstname${i}`] ?? '',
                    required
                })

            }
            result.push({
                type: 'text',
                propName: 'institution',
                label: '所属',
                value: data?.['institution'] ?? '',
                required: false
            })
            return result
        }
        return [
            {type: 'text', propName: 'email1', label: 'メールアドレス', value: '', required: true},
            {type: 'text', propName: 'lastname1', label: '姓', value: '', required: true},
            {type: 'text', propName: 'firstname1', label: '名', value: '', required: true},
        ]
    }

    const setInvitationInputField = (isAdmin: boolean, invitation: Invitation, fields: InputField<string>[]) => {
        if (isAdmin) {
            invitation.email1 = getFieldByPropName<string>(fields, 'email1')?.value || '';
            invitation.lastname1 = getFieldByPropName<string>(fields, 'lastname1')?.value || '';
            invitation.firstname1 = getFieldByPropName<string>(fields, 'firstname1')?.value || '';
            invitation.email2 = getFieldByPropName<string>(fields, 'email2')?.value || '';
            invitation.lastname2 = getFieldByPropName<string>(fields, 'lastname2')?.value || '';
            invitation.firstname2 = getFieldByPropName<string>(fields, 'firstname2')?.value || '';
            invitation.email3 = getFieldByPropName<string>(fields, 'email3')?.value || '';
            invitation.lastname3 = getFieldByPropName<string>(fields, 'lastname3')?.value || '';
            invitation.firstname3 = getFieldByPropName<string>(fields, 'firstname3')?.value || '';
            invitation.institution = getFieldByPropName<string>(fields, 'institution')?.value || '';
        } else {
            invitation.email1 = getFieldByPropName<string>(fields, 'email1')?.value || '';
            invitation.lastname1 = getFieldByPropName<string>(fields, 'lastname1')?.value || '';
            invitation.firstname1 = getFieldByPropName<string>(fields, 'firstname1')?.value || '';
        }
    }

    useEffect(() => {
        API.post<{ detail: UserDetail }>('/user/detail').then((data) => {
            if (data === undefined) {
                return
            }
            const userDetail = data.detail
            setUserDetail(userDetail)
        }).catch((e) => {
            console.error(e)
        })
    }, [setUserDetail])

    const onUpdateUserName = useCallback(async (fields: InputField<string>[]) => {

        const username: UserName = {
            lastname: '',
            firstname: ''
        }
        setInputField(username, fields);
        await API.post('/user/update-name', username)
    }, [setError])

    const onInviteUser = useCallback(async (fields: InputField<string>[]) => {

        const invitation: Invitation = {
            email1: '',
            lastname1: '',
            firstname1: '',
            institution: '',
            email2: undefined,
            lastname2: undefined,
            firstname2: undefined,
            email3: undefined,
            lastname3: undefined,
            firstname3: undefined,
        }
        setInvitationInputField(userDetail?.isAdmin ?? false, invitation, fields);
        if (userDetail?.isAdmin) {
            await API.post('/user/invite_admin', invitation)
        } else {
            await API.post('/user/invite', invitation)
        }
        const userDetailRes = await API.post<{ detail: UserDetail }>('/user/detail')
        if (userDetailRes === undefined) {
            return
        }
        setUserDetail(userDetailRes.detail)
        setInvitedUser(`${invitation.lastname1} ${invitation.firstname1}`)
        setTimeout(() => {
            setInvitedUser("")
        }, 10000)
    }, [setError, setUserDetail, setInvitedUser, userDetail])


    const handleLogout = useCallback(async () => {
        console.log('logout')
        await API.get('/logout').then(() => {
            window.location.href = '/'
        })
    }, [])


    const handleChangePassword = useCallback(() => {
        setOpenPasswordChange(true)
    }, [setOpenPasswordChange])

    const handleCloseChangePassword = useCallback(() => {
        setOpenPasswordChange(false)
    }, [setOpenPasswordChange])

    const handleBulkInput = useCallback((users: string) => {
        const userData = parseBulkInvitedUsers(users)
        if (userData === undefined) {
            return
        }
        const data = {
            email1: userData.users[0].email,
            lastname1: userData.users[0].lastName,
            firstname1: userData.users[0].firstName,
            email2: userData.users[1].email,
            lastname2: userData.users[1].lastName,
            firstname2: userData.users[1].firstName,
            email3: userData.users[2].email,
            lastname3: userData.users[2].lastName,
            firstname3: userData.users[2].firstName,
            institution: userData.company
        }
        setBulkInvitationData(data)
    }, [setBulkInvitationData])

    return (
        <Box sx={{
            backgroundColor: "#ffffff",
            width: '400px',
            right: 0,
            padding: '16px',
            margin: '16px',
            ' li': {
                'a.active': {
                    fontWeight: 'bold'
                },
                cursor: 'pointer',
                ':hover': {
                    backgroundColor: '#dddddd'
                }
            }
        }}><>
            {userDetail && (<InputCard
                id={'owner-detail'}
                title={'ユーザー情報'}
                fields={getInputField(userDetail)}
                onAction={onUpdateUserName}
                actionLabel={'更新'}
                heightFixed={false}
                design={'Box'}
            />)}
            {userDetail?.invitations !== undefined && userDetail?.invitations?.length > 0 && (
                <Box>
                    <>
                        <Box>招待した人</Box>
                        {userDetail?.invitations?.map(invitation => (<Box>{invitation}</Box>))}
                    </>
                </Box>)}
            {(((userDetail?.invitationStock || 0) - (userDetail?.invitations?.length || 0)) > 0 || userDetail?.isAdmin === true) && (
                <Box>
                    {userDetail?.isAdmin !== true ? (
                        <>
                            <Box>招待枠が残っています</Box>
                            {invitedUser === "" ? (
                                <InputCard
                                    id={'owner-detail'}
                                    title={'招待する方の情報'}
                                    fields={getInvitationInputField(false)}
                                    onAction={onInviteUser}
                                    actionLabel={'招待'}
                                    heightFixed={false}
                                    design={'Box'}
                                />) : (
                                <Box>{invitedUser} さんを招待しました。</Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Box>管理者モード</Box>
                            {invitedUser === "" ? (
                                <>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="一括入力"
                                        sx={{ width: '100%'}}
                                        multiline
                                        rows={6}
                                        defaultValue=""
                                        onChange={(e) => handleBulkInput(e.currentTarget.value)}
                                    />
                                    {bulkInvitationData['email1'] !== undefined && (
                                        <InputCard
                                            id={'owner-detail'}
                                            title={'招待する方の情報'}
                                            fields={getInvitationInputField(userDetail?.isAdmin, bulkInvitationData)}
                                            onAction={onInviteUser}
                                            actionLabel={'招待'}
                                            heightFixed={false}
                                            design={'Box'}
                                        />)}
                                </>) : (
                                <Box>{invitedUser} さんを招待しました。</Box>
                            )}
                        </>
                    )
                    }
                </Box>)}
            <Box><Button onClick={handleChangePassword}>パスワード変更</Button></Box>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Box><Button onClick={handleLogout}>ログアウト</Button></Box>
            <ErrorFeedBack feedbackText={error?.message || ''} savedTimestamp={error?.timestamp || 0}/>
            <Dialog open={openPasswordChange}
                    onClose={handleCloseChangePassword}>
                <PasswordUpdateCard onComplete={handleCloseChangePassword}/>
            </Dialog>

        </>
        </Box>
    );
}

export default UserDetailCard;