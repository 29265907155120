import { useState, useEffect } from 'react';
import {VIEW_SIZE} from "../constants/constants";

export const useViewport = () => {
    const getViewSize = (width: number) =>  {
        return width < 1000 ? VIEW_SIZE.PHONE : VIEW_SIZE.PC
    }
    const [viewportSize, setViewportSize] = useState({
        width: 0,
        height: 0,
        viewsize: VIEW_SIZE.PC
    });

    useEffect(() => {
        setViewportSize({
            width: window.innerWidth,
            height: window.innerHeight,
            viewsize: getViewSize(window.innerWidth)
        });
        const handleResize = () => {
            setViewportSize({
                width: window.innerWidth,
                height: window.innerHeight,
                viewsize: getViewSize(window.innerWidth)
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return viewportSize;
};