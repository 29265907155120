import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../theme';
import '../../styles/globals.css'
import createEmotionCache from '../createEmotionCache';
import {MainTemplate} from "../templates/MainTemplate";
import {useCallback, useState} from "react";

import {BasicTemplate} from "../templates/BasicTemplate";
import {BrowserAgent} from "@newrelic/browser-agent/loaders/browser-agent";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache
    pageProps: object

}

export default function MyApp(props: MyAppProps) {
    const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;
    const NewrelicBrowserAgent = dynamic(() => import('../atoms/NewRelicBrowserAgentComponent').then((mod) => mod.NewRelicBrowserAgentComponent), {ssr:false})
    const [browserAgent, setBrowserAgent] = useState<BrowserAgent>()
    const setBrowserAgentHandler = useCallback((agent: BrowserAgent)=> {
        if (agent === undefined) {
            return
        }
        setBrowserAgent(agent)
    }, [setBrowserAgent])
    const getBrowserAgentHandler = useCallback(()=> {
        return browserAgent
    }, [browserAgent])
    return (
        <CacheProvider value={emotionCache}>
            <NewrelicBrowserAgent setBrowserAgentHandler={(agent?: BrowserAgent)=>agent !== undefined && setBrowserAgentHandler(agent)} />
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
                <title>New Relic Dojo web</title>
            </Head>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                {props.router.pathname.startsWith('/admin') || props.router.pathname.startsWith('/gameday') || props.router.pathname.startsWith('/play') || props.router.pathname.startsWith('/score')
                    ? (<BasicTemplate>
                        <Component {...pageProps} getBrowserAgentHandler={getBrowserAgentHandler} />
                    </BasicTemplate>)
                    : (<MainTemplate>
                        <Component {...pageProps} getBrowserAgentHandler={getBrowserAgentHandler} />
                    </MainTemplate>)
                }
            </ThemeProvider>
        </CacheProvider>
    );
}