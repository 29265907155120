import {grades} from "../constants/constants";
import {Grade, InputUsers} from "../types";

export const getGrade = (badgeCount: number, mascots?: string[]) => {
    return grades.map((g: Grade, idx) => {
        if (g.count <= badgeCount && badgeCount < grades[idx + 1].count) {
            return {
                grade: g.grade,
                count: g.count,
                next: grades[idx + 1].count,
                own: badgeCount,
                title: g.title,
                mascot: g.mascot,
                message: g.message
            } as Grade
        } else if (g.count <= badgeCount) {
            mascots && mascots.push(g.mascot)
        }
    }).find(g => !!g)
}

export const parseBulkInvitedUsers = (input: string) => {
    const lines = input.split('\n')
    if (lines.length <= 1) {
        return
    }
    const company = lines[0].replace('[企業名]', '').trim()
    return { company, users: [parseUser(lines[1]), parseUser(lines[2]), parseUser(lines[3])]}
}

const parseUser = (input: string): InputUsers => {
    const userData = input.replace(/.*]/, '').split('/').map(d=>d.trim())
    return { email: userData[0], lastName: userData[1].split((/\s+/))[0].trim(), firstName: userData[1].split((/\s+/))[1]?.trim()}
}