import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useViewport} from "../hooks/DojoHooks";
import {VIEW_SIZE} from "../constants/constants";

export const GlobalTitle = () => {

    const { viewsize } = useViewport()

    return (
        <Typography
            component="h1"
            variant="h6"
            sx={{
                height: '100%',
                color:'#ffffff',
                padding: '16px',
                fontWeight: 'bold',
                flexGrow: 1
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                sx={viewsize === VIEW_SIZE.PHONE ?{ fontSize: '18px' } :{} }
            >
                New Relic Learning Dojo web
            </Stack>
        </Typography>
    )
}