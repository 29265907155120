import {Alert, Snackbar} from "@mui/material";
import * as React from "react";
import {useEffect, useState, useCallback} from "react";
import {SnackbarOrigin} from "@mui/material/Snackbar/Snackbar";

interface SavedFeedbackProps {
    feedbackText: string
    savedTimestamp: number
    anchorOrigin?: SnackbarOrigin
}
export const SavedFeedBack:React.FC<SavedFeedbackProps> = ({
    feedbackText, savedTimestamp, anchorOrigin={vertical: 'bottom', horizontal: 'left'}
                                                           }) => {

    const [open, setOpen] = useState<boolean>(false)

    useEffect(()=> {
        savedTimestamp > 0 && setOpen(true)
    }, [savedTimestamp])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={anchorOrigin}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {feedbackText}
            </Alert>
        </Snackbar>
    )
}